import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { setCookies, removeCookies, getCookie } from 'cookies-next';

import { setItem, removeItem, KEYS } from '@utils/cache';

export enum BANNER_TARGETS {
  HOMEPAGE = 'homepage',
  INTERNSHIP = 'internship',
  CANDIDATE = 'candidate',
  EMPLOYER = 'employer',
  COVER = 'cover',
}

const apiVersion = 'v1';

const config: AxiosRequestConfig = {
  baseURL: `${process.env.NEXT_PUBLIC_API_URL}/api/${apiVersion}`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const API: AxiosInstance = axios.create(config);

type BannerTarget = BANNER_TARGETS;

const cookieToken = getCookie(KEYS.AUTH_TOKEN);

if (cookieToken) {
  API.defaults.headers.common.authorization = cookieToken;
}

export const setApiLocale = (locale: string) => {
  API.defaults.headers['Accept-Language'] = locale;
};

export const setApiAuthHeaders: (token: string) => void = (token) => {
  API.defaults.headers.common.authorization = token;
  setItem(KEYS.AUTH_TOKEN, token);
  setCookies(KEYS.AUTH_TOKEN, token);
};

export const destroySession: () => void = () => {
  delete API.defaults.headers.common.authorization;
  removeItem(KEYS.AUTH_TOKEN);
  removeItem(KEYS.USER_PROFILE);
  removeCookies(KEYS.AUTH_TOKEN);
  removeCookies(KEYS.USER_ROLE);
};

type Page = number | string | string[];

type PaginatedEndointParams = {
  page?: Page;
  pageSize?: number | string;
  promoted?: boolean;
  fieldsOfExpertiseId?: number | string;
  industry?: Page | string;
  name?: string;
  highschoolProfile?: Page;
  workModel?: Page;
  practiceType?: string | string[];
};

type EmployerOfferParams = {
  slug: Page;
  page?: Page;
};

type MyOffersParams = {
  page?: Page;
};

type ApplicantsParams = {
  applicationStatus?: Page;
  offerType?: string;
  page?: Page;
  offer?: Page;
  ascending?: boolean;
  applicant?: string;
};

export const APIRoutes = {
  navigation: '/navigation',
  banners: (target: BannerTarget[]) => `/banners?target=${target.join(',')}`,
  staticPage: (target: number | string | string[]) => `/static_pages/${target}`,
  offer: (id?: number | string | string[]) => (id ? `/offers/${id}` : '/offers'),
  offers: (params?: PaginatedEndointParams) => {
    const {
      pageSize = 24,
      page = 1,
      promoted = null,
      highschoolProfile = null,
      industry = null,
      workModel = null,
      practiceType = null,
    } = params || {};

    const highschoolProfileQuery = `offer[highschool_profiles][id]=${highschoolProfile}`;
    const industryQuery = `offer[employer_profile][fields_of_expertise_id]=${industry}`;
    const workModelQuery = `offer[work_model]=${workModel}`;
    const promotedQuery = `offer[promoted]=${promoted}`;
    const practiceTypeQuery = practiceType
      ? practiceType
          .toString()
          .split(',')
          .map((p) => `offer[practice_type][]=${p}`)
      : [];

    const queryParams = [
      ...(highschoolProfile ? [highschoolProfileQuery] : []),
      ...(industry ? [industryQuery] : []),
      ...(workModel ? [workModelQuery] : []),
      ...(practiceType ? practiceTypeQuery : []),
      ...(promoted ? [promotedQuery] : []),
    ];

    return `/offers?page_size=${pageSize}&page=${page}${
      queryParams && queryParams.length ? `&${queryParams.join('&')}` : ''
    }`;
  },
  offerApply: (id: number) => `/offers/${id}/apply`,
  filters: '/filters',
  news: '/news',
  newsletter: '/newsletter_subscribers',
  employer: (slug: Page) => `/employer_profile/${slug}`,
  candidate: (slug: Page) => `/student_profile/${slug}`,
  employerOffers: (params?: EmployerOfferParams) => {
    const { page = 1, slug = null } = params || {};
    return `/employer_profile/${slug}/offers?page=${page}`;
  },
  quickSearch: (query: string) => `/offers/search_cues?name=${query}`,
  employers: (params?: PaginatedEndointParams) => {
    const { pageSize = 24, page = 1, promoted = null, fieldsOfExpertiseId = null, name = null } = params || {};
    const fieldOfExpertiseQuery = `employer[fields_of_expertise_id]=${fieldsOfExpertiseId}`;
    const promotedQuery = `employer[promoted]=${promoted}`;
    const nameQuery = `employer[name]=${name}`;

    const queryParams = [
      ...(promoted ? [promotedQuery] : []),
      ...(fieldsOfExpertiseId ? [fieldOfExpertiseQuery] : []),
      ...(name ? [nameQuery] : []),
    ];

    return `/employers?page_size=${pageSize}&page=${page}${
      queryParams && queryParams.length ? `&${queryParams.join('&')}` : ''
    }`;
  },
  highschools: '/universities',
  highschoolProfiles: '/fields_of_study',
  fieldsOfExpertises: '/fields_of_expertise',
  languages: '/languages',
  auth: {
    signup: '/users',
    signin: '/users/sign_in',
    signout: '/users/sign_out',
    password: '/users/password',
    confirmAccount: (token: string | string[]) => `/users/confirmation?confirmation_token=${token}`,
  },
  me: '/users/me',
  terms: '/users/me/terms',
  myOffers: (params?: MyOffersParams) => {
    const { page = 1 } = params || {};
    return `/users/me/offers?page=${page}`;
  },
  studentApplications: (page = 1) => `/users/me/applications?page=${page}`,
  applicants: (params?: ApplicantsParams) => {
    const {
      applicationStatus = null,
      offerType = null,
      page = 1,
      offer = null,
      ascending = false,
      applicant = '',
    } = params || {};
    const applicationStatusQuery = applicationStatus ? `&application[status]=${applicationStatus}` : '';
    const offerTypeQuery = offerType ? `&application[offers][practice_type]=${offerType}` : '';
    const offerQuery = offer ? `&application[offer_id]=${offer}` : '';
    const sortQuery = `&sort=${ascending ? 'asc' : 'desc'}`;
    const studentName = applicant ? `&applicant=${applicant}` : '';

    return `/users/me/applicants?page=${page}${applicationStatusQuery}${offerTypeQuery}${offerQuery}${sortQuery}${studentName}`;
  },
  applicationCounts: '/users/me/applicants/counts',
  upadteStudentProfile: (id: number) => `/student_profile/${id}`,
  upadteEmployerProfile: (id?: number) => (id ? `/employer_profile/${id}` : '/employer_profile'),
  contact: '/contact',
  updateApplicationStatus: (offerId: number, applicationId: number) =>
    `offers/${offerId}/applications/${applicationId}`,
  batchUpdateApplicationStatus: '/users/me/applicants',
  offersOfLoggedEmployer: (name: string) => `/users/me/offer_cues?name=${name}`,
};
